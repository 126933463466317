import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environment';
import { buildApiUrl } from '@helpers/environment.helper';
import { EmailFooter } from '@interfaces/email-footer.interface';
import { AgentsBulkDeletePayload } from '@interfaces/payloads/agents-bulk-delete-payload.interface';
import { GetAgentLogsPayload } from '@interfaces/payloads/get-agent-logs-payload.interface';
import { GetAgentsPayload } from '@interfaces/payloads/get-agents-payload.interface';
import { UpdateEmailFooterPayload } from '@interfaces/payloads/update-email-footer-payload.interface';
import { DataResponse } from '@interfaces/responses/data-response.interface';
import { DataTableResponse } from '@interfaces/responses/data-table-response.interface';
import { UploadEmailFooterPhotoResponse } from '@interfaces/responses/upload-email-footer-photo-response.interface';
import { AgentLog } from '@models/agent-log.model';
import { Agent } from '@models/agent.model';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AgentsService {
  private readonly http = inject(HttpClient);

  createAgent(agent: Agent): Observable<Agent> {
    return this.http.post(`${buildApiUrl(environment.api)}/v1/agent`, agent).pipe(map(response => response as Agent));
  }

  getAgents(payload: GetAgentsPayload): Observable<DataTableResponse<Agent>> {
    const { page, params } = payload;
    return this.http.get(`${buildApiUrl(environment.api)}/v1/agents`, { params }).pipe(map(response => response as DataTableResponse<Agent>));
  }

  getAgentLogs(payload: GetAgentLogsPayload): Observable<DataTableResponse<AgentLog>> {
    const { page, params } = payload;
    const agentId = page.replace('/', '');
    return this.http
      .get(`${buildApiUrl(environment.api)}/v1/agent/${agentId}/audits`, { params })
      .pipe(map(response => response as DataTableResponse<AgentLog>));
  }

  getAgent(id: number): Observable<DataResponse<Agent>> {
    return this.http.get(`${buildApiUrl(environment.api)}/v1/agent/${id}`).pipe(map(response => response as DataResponse<Agent>));
  }

  updateAgent(agent: Agent): Observable<Agent> {
    return this.http.put(`${buildApiUrl(environment.api)}/v1/agent/${agent.id}`, agent).pipe(map(response => response as Agent));
  }

  bulkDelete(payload: AgentsBulkDeletePayload): Observable<never> {
    return this.http.post(`${buildApiUrl(environment.api)}/v1/agents/bulk-delete`, payload).pipe(map(response => response as never));
  }

  startWork(): Observable<never> {
    return this.http.post(`${buildApiUrl(environment.api)}/v1/agent/start-work`, {}).pipe(map(response => response as never));
  }

  stopWork(): Observable<never> {
    return this.http.put(`${buildApiUrl(environment.api)}/v1/agent/stop-work`, {}).pipe(map(response => response as never));
  }

  getEmailFooters(id: string): Observable<DataTableResponse<EmailFooter>> {
    return this.http.get(`${buildApiUrl(environment.api)}/v1/email-footers/${id}`).pipe(map(response => response as DataTableResponse<EmailFooter>));
  }

  updateEmailFooter(id: number, payload: UpdateEmailFooterPayload): Observable<EmailFooter> {
    return this.http.put(`${buildApiUrl(environment.api)}/v1/email-footers/${id}`, payload).pipe(map(response => response as EmailFooter));
  }

  createEmailFooter(payload: UpdateEmailFooterPayload): Observable<EmailFooter> {
    return this.http.post(`${buildApiUrl(environment.api)}/v1/email-footers`, payload).pipe(map(response => response as EmailFooter));
  }

  uploadEmailFooterPhoto(id: number, data: FormData): Observable<UploadEmailFooterPhotoResponse> {
    return this.http
      .post(`${buildApiUrl(environment.api)}/v1/email-footers/${id}/photo`, data)
      .pipe(map(response => response as UploadEmailFooterPhotoResponse));
  }
}
